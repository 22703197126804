import React from 'react'
import { Box } from 'theme-ui'

export const Button = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as='button'
    variant='common'
    style={{ width: '100%', maxWidth: 417, minHeight: 50 }}
    {...props}
    __themeKey='buttons'
    __css={{
      appearance: 'none',
      display: 'inline-block',
      textAlign: 'center',
      lineHeight: 'inherit',
      textDecoration: 'none',
      fontSize: 'large',
      px: 3,
      py: 2,
      color: 'white',
      bg: 'green',
      ':hover': {
        bg: '#718096',
      },
      border: 0,
      borderRadius: 0,
      width: '90%'
    }}
  />
))
