import { graphql } from 'gatsby'
import ProductPage from '../containers/Product'

export default ProductPage

export const pageQuery = graphql`
  query ProductPageQuery($id: String!) {
    product: wcProducts(id: { eq: $id }) {
      id
      name
      sku
      short_description
      images {
        localFile {
          childImageSharp {
            fluid(maxWidth: 533, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      attributes {
        name
        options
        id
      }
      categories {
        name
        slug
      }
    }
    trust: file(absolutePath: {regex: "/trust-seal-pac-beauty.png/"}) {
      childImageSharp {
        fluid(cropFocus: CENTER, maxWidth: 417, maxHeight: 87) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
