import React from 'react'
import { Box, Grid, Heading, Text, Container, Card } from 'theme-ui'
import CardSection from '../layout/CardSection'
import Divider from '@components/Divider'
import { Button } from '../widgets/Buttons/BuyButton'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import Seo from '@widgets/Seo'
import { Main } from '@layout'
import Breadcrumbs from '../components/Breadcrumbs/ProductCrumbs'
import { SidebarMenu } from '../components/SidebarMenu/SidebarMenu'
import { SidebarLeft } from '../layout/SidebarLeft'
import Section from '@components/Section'

export default props => {
  const prodId = props.data.product.id
  const name = props.data.product.name
  const sdesc = props.data.product.short_description
  const sku = props.data.product.sku
  const fluid = props.data.product.images[0].localFile.childImageSharp.fluid
  const atts = props.data.product.attributes
  const trust = props.data.trust.childImageSharp.fluid
  const catName = props.data.product.categories[0].name
  const catSlug = props.data.product.categories[0].slug

  const styles = {
    wrapper: {
      pl: [0, 10]
    },
    heading: {
      fontSize: '2.5em'
    },
    running: {
      color: `grayDark`,
      fontWeight: `body`,
      fontSize: `1.25em`,
      span: {
        fontWeight: 'bold'
      }
    },
    center: {
      ml: 'auto',
      mr: 'auto',
      textAlign: 'center'
    },
    highlight: {
      span: {
        fontWeight: 'bold'
      }
    },
    flex: {
      display: 'flex'
    },
    sidebar: {
      display: ['none', 'none', 'none', 'block'],
      minWidth: `auto`
    },
    sidecard: {
      marginTop: -25
    }
  }
  return (
    <>
      <Seo
        title={name + ' Available for Private Label'}
        description={
          name +
          ' makes a great addition to any private label skincare line.  Learn how you can incorporate ' +
          name +
          ' into your own branded product line.'
        }
      />
      <Divider space={3} />
      <Container variant='wide' sx={styles.flex}>
        <Breadcrumbs catName={catName} catSlug={catSlug} name={name} />
      </Container>
      <Divider space={2} />
      <Container variant='wide' sx={styles.flex}>
        <SidebarLeft sx={styles.sidebar}>
          <Section title='Product Menu'>
            <Card variant='paper' sx={styles.sidecard}>
              <SidebarMenu />
            </Card>
          </Section>
        </SidebarLeft>
        <Main>
          <Divider space={2} />
          <Grid gap={10} columns={[1, 1, 1, 2]}>
            <Box>
              <Img
                fluid={fluid}
                title={name}
                alt={'bottle of private labeled ' + name + ' sitting on table'}
                loading='lazy'
                imgStyle={{
                  width: '100%',
                  maxWidth: 533,
                  maxHeight: 533,
                  ml: 'auto',
                  mr: 'auto',
                  textAlign: 'center'
                }}
                style={{
                  width: '100%',
                  maxWidth: 533,
                  maxHeight: 533,
                  ml: 'auto',
                  mr: 'auto',
                  textAlign: 'center'
                }}
                placeholderStyle={{
                  width: '100%',
                  maxWidth: 533,
                  maxHeight: 533,
                  ml: 'auto',
                  mr: 'auto',
                  textAlign: 'center'
                }}
              />
            </Box>
            <Box>
              <Box sx={styles.wrapper}>
                <Heading variant='h2' sx={styles.heading}>
                  {name}
                </Heading>
                <Divider space={1} line />
                <Divider space={2} />
                <Text
                  sx={styles.running}
                  dangerouslySetInnerHTML={{ __html: sdesc }}
                ></Text>
				{atts.map(
                  att =>
                    att.id === '4' && (
                      <React.Fragment key={prodId + att.id}>
						<Text sx={styles.running}>
							<strong><sup>**</sup>Professional License Required for this product</strong>
						</Text>
                      </React.Fragment>
                    )
                )}
                <Divider />
                <Box sx={styles.center}>
                  <Img
                    fluid={trust}
                    title=''
                    alt=''
                    loading='lazy'
                    imgStyle={{
                      width: '100%',
                      maxWidth: 417,
                      maxHeight: 87,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      textAlign: 'center'
                    }}
                    style={{
                      width: '100%',
                      maxWidth: 417,
                      maxHeight: 87,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      textAlign: 'center',
                      paddingLeft: 5,
                      paddingRight: 5
                    }}
                    placeholderStyle={{
                      width: '100%',
                      maxWidth: 417,
                      maxHeight: 87,
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      textAlign: 'center'
                    }}
                  />

                  <Divider space={3} />
                  <Button as={Link} to='/contact/'>
                    Inquire About Product
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Divider space={3} line />
          <CardSection title='Details'>
          <Text sx={styles.running}>
            <span>{name}</span> is available for private label and makes a great
            addition to any luxury skincare line. Samples, product brochures,
            pricing and ingredient information are available upon request.
            Please click the
            <span> Inquire About Product</span> button to get in touch with us.{' '}
            {name} is made in the USA and is cruelty-free.
            <ul>
              <li>
                Size:
                {atts.map(
                  att =>
                    att.id === '1' && (
                      <React.Fragment key={prodId + att.id}>
                        {' '}
                        {att.options[0]}{' '}
                      </React.Fragment>
                    )
                )}
                (or customer choice)
              </li>
                
 
              <li>MOQ: 48 Units</li>
              <li>Packaging: Customer Choice</li>
              <li>SKU: {sku}</li>
              <li>
                Ingredient List: <Link to='/contact/'>Contact Us</Link>
              </li>
              <li>
                Product Brochure: <Link to='/contact/'>Contact Us</Link>
              </li>
              <li>
                Samples: <Link to='/contact/'>Contact Us</Link>
              </li>
              <li>
                Price: <Link to='/contact/'>Contact Us</Link>
              </li>
              <li>In Stock and Available</li>
              <li>
                <span>MADE IN USA</span>
              </li>
            </ul>
            </Text>
          </CardSection>
        </Main>
      </Container>
    </>
  )
}
